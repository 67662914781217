<template>
    <Bar
      :key="chartKey"
      :data="chartData"
      :options="computedOptions"
      :width="width"
      :height="height"
      id="hospital-type-bar-chart"
    />
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
  
  ChartJS.register(ChartDataLabels, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
  
  export default {
    name: 'HospitalTypeChart',
    components: { Bar },
    props: {
      data: { type: Object, required: true },
      options: {
        type: Object, required: true
      },
      width: { type: Number, default: 400 },
      height: { type: Number, default: 200 },
    },
    data() {
      return {
        chartData: {},
        computedOptions: {},
        chartKey: 0 // Add a key to force re-rendering
      };
    },
    watch: {
      data: {
        deep: true,
        handler() {
          this.updateChartData();
        }
      },
      options: {
        deep: true,
        handler() {
          this.updateChartData();
        }
      }
    },
    created() {
      this.updateChartData();
    },
    methods: {
      updateChartData() {
        const data = this.data;
  
        // console.log("START: chartData");
  
        // Filter out datasets where all values are zero
        //const filteredDatasets = data.datasets.filter(dataset => !dataset.data.every(value => value === 0));
  
        const filteredDatasets = data.datasets;
  
        if (filteredDatasets.length === 0) {
          //console.log("All datasets are empty after filtering");
          this.chartData = {
            labels: [],
            datasets: [],
            originalData: []
          };
          this.chartKey += 1;
          return;
        }
  
        const originalData = filteredDatasets.map(dataset => ({
          label: dataset.label,
          backgroundColor: dataset.backgroundColor || 'rgba(0, 123, 255, 0.5)',
          data: dataset.data.slice()
        }));
  
        // console.log("originalData:" + JSON.stringify(originalData));
  
        const totalDataPerItem = [];
        for (let i = 0; i < filteredDatasets[0].data.length; i++) {
          let total = 0;
          for (let j = 0; j < filteredDatasets.length; j++) {
            total += filteredDatasets[j].data[i];
          }
          totalDataPerItem.push(total);
        }
        //console.log("totalDataPerItem:" + JSON.stringify(totalDataPerItem));
  
        // const filteredLabels = data.labels.filter((label, index) => {
        //   return !filteredDatasets.every(dataset => dataset.data[index] === 0);
        // });
        const filteredLabels = data.labels;
  
        const normalizedDatasets = filteredDatasets.map((dataset) => {
          const normalizedData = dataset.data.map((value, dataIndex) => {
            const total = totalDataPerItem[dataIndex];
            if (total) {
              return parseFloat(((value / total) * 100).toFixed(2));
            } else {
              return 0;
            }
          });
  
          return {
            label: dataset.label,
            backgroundColor: dataset.backgroundColor || 'rgba(0, 123, 255, 0.5)',
            data: normalizedData,
            originalData: dataset.data.slice()
          };
        });
        // console.log("normalizedDatasets:" + JSON.stringify(normalizedDatasets));
  
        this.chartData = {
          labels: filteredLabels,
          datasets: normalizedDatasets,
          originalData: originalData
        };
  
        this.computedOptions = {
          //responsive:false,
          maintainAspectRatio: false,
          responsive: false,  
          indexAxis: this.options.indexAxis,
          scales: {
            x: { 
              max: 100,
              stacked: true ,
              ticks:{
                callback:function(value){
                  return value +'%'; // Append '%' to each label
                }
              }
            },
            y: { 
              stacked: true,
              position: 'right', // Move y-axis labels to the right
              ticks: {
                padding: 5, // Add padding to move labels to the right
                align: 'center' // Center-align the labels
              },
              grid: {
                display: false // Hide gridlines
              }
            }
          },
          plugins: {
            tooltip: { 
              callbacks: {
                label: (context) => {
                  const datasetOriginalData = context.dataset.originalData || [];
                  const originalValue = datasetOriginalData[context.dataIndex] !== undefined ? datasetOriginalData[context.dataIndex] : 0;
                  const percentageValue = context.raw !== undefined ? context.raw.toFixed(2) : '0.00';
                  return `${context.dataset.label}: ${originalValue} (${percentageValue}%)`;
                },
              },
            },
            datalabels: {
              display: true,
              color: 'white', // Change text color to white
              formatter: (value) => {
                const percentageValue = value.toFixed(2);
                return percentageValue !== '0.00' ? `${percentageValue}%` : ''; // Hide 0.00%
              },
            },
            legend: this.options.plugins.legend,
            title: this.options.plugins.title,
          },
        };
  
        // Force re-render by changing the key
        this.chartKey += 1;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any additional styles if needed */
  </style>
  