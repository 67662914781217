<template>
  <div class="table" :style="{ width: width, height: height }">
    <table v-if="this.tableData && this.tableData.HOSPITALS && this.tableData.DATA">
      <thead>
        <tr>
          
          <th>병원</th>
          <template v-if="!isColumnZero('POS')">
            <th>POS</th>
          </template>
          <template v-if="!isColumnZero('NEG')">
            <th>NEG</th>
          </template>
          <template v-if="!isColumnZero('INC')">
            <th>INC</th>
          </template>
          
        </tr>
      </thead>
      <tbody>
        <tr v-for="hospital in tableData.HOSPITALS" :key="hospital">
          <template v-if="!isColumnZero('POS') && !isColumnZero('NEG') && !isColumnZero('INC')">
            <td>{{ hospital }}</td>
          </template>
          <template v-if="!isColumnZero('POS')">
            <td>{{ tableData.DATA[hospital].POS }} ({{ calculateProportion(hospital, 'POS') }}%)</td>
          </template>
          <template v-if="!isColumnZero('NEG')">
            <td>{{ tableData.DATA[hospital].NEG }} ({{ calculateProportion(hospital, 'NEG') }}%)</td>
          </template>
          <template v-if="!isColumnZero('INC')">
            <td>{{ tableData.DATA[hospital].INC }} ({{ calculateProportion(hospital, 'INC') }}%)</td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'HospitalScreenTable',
  props: {
    tableData: {
      type: Object,
      default: () => ({
        HOSPITALS: ['A', 'B', 'C', 'D'],
        DATA: {
          A: { POS: 1, NEG: 2, INC: 2},
          B: { POS: 1, NEG: 2, INC: 2},
          C: { POS: 1, NEG: 2, INC: 2},
          D: { POS: 1, NEG: 2, INC: 2}
        }
      })
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: 'auto'
    }
  },
  methods: {
    calculateProportion(hospital, type) {
      const data = this.tableData.DATA[hospital];
      const total = data.POS + data.NEG + data.INC;
      const value = data[type];
      


      let proportion="0.00";
      if (total != 0){
        proportion = ((value / total) * 100).toFixed(2);
      }
      
      return proportion;
      
    },
    isColumnZero(type) {
      return Object.values(this.tableData.DATA).every(data => data[type] === 0);
    }
  }
}
</script>

<style scoped>
.table {
  margin-top: 12px;
  width: v-bind(width);
  height: v-bind(height);
}
.table th,
.table td {
  text-align: center;
  border: none;
  font-size: 13px; /* Smaller font size */
}
.table tbody {
  margin-top: 10px; /* 10px margin at the top of tbody */
}
.table tr {
  border-bottom: none;
}
</style>
